import { Button, Typography } from '@material-ui/core';
import { graphql, Link } from 'gatsby';
import { keyBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Twitter } from 'react-feather';
import { Centered } from '../components/Centered';
import {
  blogsToChartData,
  RankAreaChart,
  RankChart
} from '../components/Charts/RankChart';
import { DevLogo } from '../components/DevLogo';
import { Points } from '../components/Points';
import {
  ControlButton,
  ControlsContainer,
  RankingPageHeader
} from '../components/RankingPageHeader';
import { RankingsHero } from '../components/RankingsHero';
import { SEO } from '../components/SEO';
import { SubmitBlogDialog } from '../components/SubmitBlogDialog';
import { TopicBadge } from '../components/TopicBadge';
import { TrendingLink, TrendingPost } from '../components/TrendingPage';
import { useDialogState } from '../hooks/useDialogState';
import { useMediaQuery } from '../hooks/useMediaQuery';
import { LayoutWithoutHeader as Layout } from '../layouts/Layout';
import {
  getTopPageSortedByLastNDays,
  NEW_BLOG_CUTOFF_DAYS,
  useBlogsForTopicSortedByLastNDays,
  Mode
} from '../services/ranking';
import styled from '../styled';
import { SubmissionTopic } from '../types/Submission';
import { Topic } from '../types/Topic';
import { SocialSharingButtons } from '../components/SocialSharingButtons';

const CreditsP = styled('p')`
  margin-top: ${(p) => p.theme.spacing(6)}px;
  line-height: 2rem;
  opacity: 0.5;
  padding: 0 ${(p) => p.theme.spacing(2)}px;
`;

const RelatedTopicsTitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    border-bottom: 1px solid;
  }

  @media (max-width: 800px) {
    display: block;
    text-align: center;
    padding: 0 ${(p) => p.theme.spacing(2)}px;
    margin-bottom: ${(p) => p.theme.spacing(3)}px;
  }
`;

const ChartWrapper = styled('div')`
  background-color: #111216;
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
  min-height: 450px;

  @media (max-width: 650px) {
    min-height: 250px;
  }
`;

const ChartWrapperInner = styled('div')`
  max-width: 1000px;
  margin: ${(p) => p.theme.spacing(1)}px auto;
  padding: ${(p) => p.theme.spacing(3)}px 0;
  overflow: hidden;

  div > div {
    overflow: hidden;
  }
`;

const ChartWrapperTitleWrapper = styled('div')`
  margin-top: ${(p) => p.theme.spacing(3)}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto;

  @media (max-width: 800px) {
    display: block;
    text-align: center;
    margin-bottom: ${(p) => p.theme.spacing(2)}px;
  }
`;

const Container = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  align-items: top;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    padding: ${(p) => p.theme.spacing(1)}px;
  }
`;

const FormWrapper = styled('div')`
  background-color: #111216;
  border-radius: 8px;
  overflow: hidden;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    margin: 0 12px;
  }
`;

const FormInner = styled('div')`
  padding: ${(p) => p.theme.spacing(4)}px ${(p) => p.theme.spacing(3)}px;

  p {
    line-height: 2rem;
  }
`;

const CoverArt = styled('div')`
  width: 33%;
  position: absolute;
  right: 48px;
  transform: translateY(-80%) rotate(15deg);
  z-index: 1;

  svg {
    fill: ${(p) => p.theme.palette.grey[700]};
  }

  svg.clojure path:first-child {
    fill: none;
  }

  @media (max-width: 800px) {
    width: 50%;

    svg {
      opacity: 0.3;
    }
  }
`;

const Wrapper = styled('div')`
  max-width: 1000px;
  margin: 0 auto ${(p) => p.theme.spacing(2)}px;
`;

const PointsWrapper = styled('div')`
  border-left: 1px solid #191c20;
  height: 100%;
`;

const RankRow = styled('div')`
  display: grid;
  grid-template-columns: 250px 1fr 125px;
  grid-template-areas: 'screenshot description points';
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
  padding: ${(p) => p.theme.spacing(3)}px 0 ${(p) => p.theme.spacing(3)}px
    ${(p) => p.theme.spacing(3)}px;
  background-color: #111216;
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  border: 1px solid transparent;
  transform: scale(1);
  transition: transform 0.2s ease-in;
  align-items: center;

  @media (min-width: 900px) {
    &:hover {
      transform: scale(1.01);
      border: 1px solid ${(p) => p.theme.palette.primary.dark};
      box-shadow: 0 5px 20px 0 rgb(24 144 255 / 22%);
    }
  }

  @media (max-width: 900px) {
    align-items: center;
    max-width: 500px;
    padding: ${(p) => p.theme.spacing(2)}px;
    margin: 0 auto ${(p) => p.theme.spacing(3)}px;
    background-color: transparent;
    grid-row-gap: ${(p) => p.theme.spacing(3)}px;
    grid-template-areas:
      'screenshot points'
      'description description';
    grid-template-columns: 2fr 125px;
  }

  @media (max-width: 700px) {
    grid-template-columns: 2fr 100px;
  }
`;

const RankScreenshotWrapper = styled('div')`
  grid-area: screenshot;
  height: 250px;
  overflow: hidden;
  position: relative;
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  box-shadow: 0 5px 20px 0 rgb(24 144 255 / 22%);
  border: 2px solid ${(p) => p.theme.palette.primary.main};
  text-align: center;
  opacity: 0.9;
  background-color: ${(p) => p.theme.palette.primary.dark};

  @media (max-width: 1000px) {
    height: 240px;
    width: 100%;
  }

  @media (max-width: 900px) {
    height: 260px;
    width: 100%;
  }

  @media (max-width: 600px) {
    height: 260px;
    width: 100%;
  }

  @media (max-width: 500px) {
    height: 230px;
    width: 100%;
  }

  @media (max-width: 400px) {
    height: 180px;
    width: 100%;
  }
`;

const SocialPreviewImage = styled('img')`
  display: block;
  margin: 0 auto;
  max-width: 100%;
  opacity: 0.9;
`;

const RankScreenshot = styled('img')`
  background: url(${(p) => p.src});
  width: 100%;
`;

const BlogTitle = styled('h3')`
  display: inline-block;
  color: ${(p) => p.theme.palette.grey[100]};
  font-size: 24px;
  line-height: 24px;
  margin: 6px 0;

  a {
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.2s ease-in;

    &:hover {
      border-bottom: 3px solid;
    }
  }
`;

const BlogDescription = styled('p')`
  color: ${(p) => p.theme.palette.grey[300]};
  font-size: 16px;
  line-height: 36px;
`;

const TwitterInfo = styled('span')`
  display: flex;
  align-items: center;
  font-weight: 600;
  display: inline-block;

  a {
    color: ${(p) => p.theme.palette.primary.light};
    position: relative;
    top: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    transform: scale(1);
    transition: transform 0.1s ease-in;

    &:hover {
      transform: scale(1.1);
      color: ${(p) => p.theme.palette.primary.dark};
    }
  }
`;

const BlogDescriptionWrapper = styled('div')`
  grid-area: description;
`;

const RelatedTopicsTitle = styled('h2')`
  font-size: 1rem;
  line-height: 2rem;
  color: ${(p) => p.theme.palette.primary.dark};
  letter-spacing: 2px;
  text-transform: uppercase;

  @media (max-width: 800px) {
    text-align: center;
  }
`;

const RelatedTopicsWrapper = styled('div')`
  max-width: 1000px;
  margin: ${(p) => p.theme.spacing(6)}px auto ${(p) => p.theme.spacing(1)}px;

  @media (max-width: 800px) {
    margin: ${(p) => p.theme.spacing(3)}px auto ${(p) => p.theme.spacing(1)}px;
  }
`;

const RelatedTopicGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-row-gap: ${(p) => p.theme.spacing(1)}px;
    padding: ${(p) => p.theme.spacing(2)}px;
  }
`;

const RelatedTopicArt = styled('div')`
  width: 24px;

  svg {
    fill: ${(p) => p.theme.palette.grey[700]};
  }

  svg.clojure path:first-child {
    fill: none;
  }
`;

const RelatedTopicsCard = styled<typeof Link, { topic: Topic }>(Link)`
  position: relative;
  background: ${(p) => p.theme.palette.grey[800]};
  padding: ${(p) => p.theme.spacing(1)}px ${(p) => p.theme.spacing(2)}px;
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  transform: scale(1);
  transition: transform 0.1s ease-in;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    transform: scale(1.05);
    border: 1px solid ${(p) => p.theme.palette.primary.dark};
    box-shadow: 0 5px 20px 0 rgb(24 144 255 / 22%);
  }
`;

const RelatedTopicsHeadline = styled('h3')`
  font-size: 1rem;
  line-height: 2rem;
  margin: 0;
`;

const IndexPage = ({
  pageContext: { topicId },
  data: {
    allTopic: { edges }
  }
}: {
  pageContext: { topicId: string };
  data: {
    allTopic: { edges: { node: Topic }[] };
  };
}) => {
  const [scoreRange, setScoreRange] = useState(30);
  const [mode, setMode] = useState<Mode>('all');
  const allTopics = edges
    .map((e) => e.node)
    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  const topic = allTopics.find((e) => e.id === topicId);
  const topicsByKey = keyBy(allTopics, 'id');
  const tTopicId = topic!.id as SubmissionTopic;
  const blogs = useBlogsForTopicSortedByLastNDays(tTopicId, mode, scoreRange);
  const { dialogOpen, closeDialog, openDialog } = useDialogState();
  const isMobile = useMediaQuery('(max-width: 650px)');
  const [selectedChart, setSelectedChart] = useState<'volume' | 'rank'>(
    'volume'
  );
  const blogsCount = blogs.length < 10 ? '' : blogs.length.toString();
  const chartData = useMemo(
    () => blogsToChartData(blogs.slice(0, 10), 'absolute', 7),
    [blogs, isMobile]
  );

  if (!topic) {
    return null;
  }

  const headline = (topic.headline || topic.title).replace(
    'NUMBER',
    blogsCount
  );

  const slugWithSlash = `${topic.slug}/`;
  const tweet = `Just found this awesome list of ${topic.name} blogs! It updates itself every hour based on which content gets shared on Twitter. Check it out`;
  const postUrl = encodeURIComponent(
    'https://bloggingfordevs.com/' + slugWithSlash
  );
  const tweetText = encodeURIComponent(tweet + ' via @bloggingfordevs');
  const twitterHref = `https://twitter.com/share?text=${tweetText}&url=${postUrl}`;

  return (
    <Layout>
      <SEO
        title={topic.title.replace('NUMBER', blogsCount)}
        siteUrl="https://bloggingfordevs.com"
        description={topic.description.replace('NUMBER', blogsCount)}
        useTitleTemplate={false}
        socialSharingImage={`/images/trends-images/${topic.slug}.png`}
        noIndex={topic.noindex}
        pathname={`${topic.slug}/`}
      />
      <RankingsHero headline={headline} topic={topic} parentUrl={'/trends/'}>
        <CoverArt>
          <DevLogo id={topic.id} />
        </CoverArt>
      </RankingsHero>
      <Wrapper>
        <RankingPageHeader
          title={`${topic.name} blogs`}
          scoreRange={scoreRange}
          setScoreRange={setScoreRange}
          mode={mode}
          setMode={setMode}
        />

        {!blogs.length && (
          <>
            {mode === 'new' && (
              <Centered height={200}>
                No new submissions in the last {NEW_BLOG_CUTOFF_DAYS} days.
              </Centered>
            )}
            {mode === 'all' && (
              <Centered
                height={200}
                style={{ backgroundColor: '#111216', borderRadius: '8px' }}
              >
                <p>
                  We're not monitoring any blogs for this topic yet! Could you
                  help us by submitting one?
                </p>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={openDialog}
                  style={{ marginTop: '24px' }}
                >
                  Recommend a blog
                </Button>
              </Centered>
            )}
          </>
        )}

        {blogs.map((blog, i) => {
          const topPage = getTopPageSortedByLastNDays(blog, scoreRange).filter(
            (p) => p.pathname !== '/'
          )[0];
          return (
            <RankRow key={blog.name}>
              <RankScreenshotWrapper>
                <RankScreenshot
                  alt={blog.name}
                  src={blog.screenshotUrl.m || blog.screenshotUrl.l}
                />
              </RankScreenshotWrapper>
              <BlogDescriptionWrapper>
                <BlogTitle>
                  <a href={blog.url} target="_blank" rel="noopener">
                    {i + 1}. {blog.name}
                  </a>
                </BlogTitle>
                <BlogDescription>{blog.description}</BlogDescription>
                {topPage && (
                  <>
                    <TrendingPost>TRENDING POST:</TrendingPost>
                    <TrendingLink blog={blog} page={topPage} />
                    <br />
                    <br />
                  </>
                )}
                <div>
                  {blog.topics.map((topic) => (
                    <TopicBadge
                      variant="blue"
                      to={`/${topicsByKey[topic].slug}/`}
                      key={topicsByKey[topic].id}
                    >
                      {topicsByKey[topic].name}
                    </TopicBadge>
                  ))}
                  <TwitterInfo>
                    <a
                      href={`https://twitter.com/${blog.twitterHandle}`}
                      target="_blank"
                      rel="noopener"
                    >
                      <Twitter size={16} />
                    </a>
                  </TwitterInfo>
                </div>
              </BlogDescriptionWrapper>
              <PointsWrapper>
                <Points scores={blog.stats} scoreRange={scoreRange} />
              </PointsWrapper>
            </RankRow>
          );
        })}
      </Wrapper>
      {!!blogs.length && (
        <div>
          <ChartWrapperTitleWrapper>
            <RelatedTopicsTitle>7-Day History • Top 10</RelatedTopicsTitle>
            <ControlsContainer>
              <ControlButton
                selected={selectedChart === 'volume'}
                onClick={() => {
                  setSelectedChart('volume');
                }}
              >
                Points
              </ControlButton>
              <ControlButton
                selected={selectedChart === 'rank'}
                onClick={() => {
                  setSelectedChart('rank');
                }}
              >
                Rank
              </ControlButton>
            </ControlsContainer>
          </ChartWrapperTitleWrapper>
          <ChartWrapper>
            <ChartWrapperInner>
              {selectedChart === 'volume' && (
                <RankAreaChart data={chartData} height={isMobile ? 250 : 400} />
              )}
              {selectedChart === 'rank' && (
                <RankChart data={chartData} height={isMobile ? 250 : 400} />
              )}
            </ChartWrapperInner>
          </ChartWrapper>
        </div>
      )}
      <Wrapper style={{ marginTop: '60px' }}>
        <Container>
          <div>
            <RelatedTopicsTitle>
              Like this collection of {topic.name} blogs?
            </RelatedTopicsTitle>
            <FormWrapper>
              <div>
                <SocialPreviewImage
                  src={`/images/trends-images/${topic.slug}.png`}
                  alt={`Best ${topic.keyword}`}
                />
              </div>
              <FormInner>
                <Typography variant="body1" component="p" paragraph>
                  We're trying to create the best place to find quality and
                  creative content, written by individual developers and
                  technical experts. Help us get the word out!
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={(e: React.SyntheticEvent) => {
                    e.preventDefault();
                    window.open(
                      twitterHref,
                      'twitter-share',
                      'width=550,height=235'
                    );
                  }}
                  href={twitterHref}
                  style={{ marginBottom: '12px', marginTop: '24px' }}
                >
                  <Twitter size={16} /> &nbsp; Share this on Twitter
                </Button>
                <br />
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={openDialog}
                >
                  Or, recommend a blog
                </Button>
              </FormInner>
            </FormWrapper>
          </div>
        </Container>
      </Wrapper>
      <RelatedTopicsWrapper>
        <RelatedTopicsTitleWrapper>
          <RelatedTopicsTitle>
            Read Software Blogs on Related Topics
          </RelatedTopicsTitle>
          <Link to="/trends/">Back to all topics</Link>
        </RelatedTopicsTitleWrapper>
        <RelatedTopicGrid>
          {topic.relatedTopics.map((topicId) => (
            <RelatedTopicsCard
              topic={topicsByKey[topicId]}
              key={topicId}
              to={`/${topicsByKey[topicId].slug}/`}
            >
              <RelatedTopicsHeadline>
                {topicsByKey[topicId].name} Blogs
              </RelatedTopicsHeadline>
              <RelatedTopicArt>
                <DevLogo id={topicId} />
              </RelatedTopicArt>
            </RelatedTopicsCard>
          ))}
        </RelatedTopicGrid>
        <CreditsP>
          <strong>Acknowledgements</strong> &mdash; Thanks to{' '}
          <a
            href="https://www.heropatterns.com/"
            target="_blank"
            style={{ borderBottom: '1px solid' }}
          >
            Hero Patterns
          </a>{' '}
          and{' '}
          <a
            href="https://devicon.dev/"
            target="_blank"
            style={{ borderBottom: '1px solid' }}
          >
            Devicon
          </a>{' '}
          for SVG assets used on this site. Plus, thanks to everyone who's
          submitted their favorite blogs so far! We'd love your suggestions for
          how to make this list better on Twitter,{' '}
          <a
            href="https://twitter.com/bloggingfordevs"
            target="_blank"
            style={{ borderBottom: '1px solid' }}
          >
            @bloggingfordevs
          </a>
          .
        </CreditsP>
      </RelatedTopicsWrapper>
      <SubmitBlogDialog open={dialogOpen} onClose={closeDialog} />
      <SocialSharingButtons
        slug={`${topic.slug}/`}
        title={headline}
        tweet={tweet}
      />
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allTopic {
      edges {
        node {
          name
          relatedTopics
          slug
          title
          description
          headline
          background
          keyword
          color
          pattern
          mode
          noindex
          id
        }
      }
    }
  }
`;

export default IndexPage;
