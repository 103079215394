import { Link } from 'gatsby';
import { Button } from '@material-ui/core';
import React from 'react';
import styled from '../styled';
import { HeroPattern } from './HeroPattern';
import { Topic } from '../types/Topic';
import { TrendsNav } from './TrendsNav';

const HeroWrapper = styled<'header', { background: string }>('header')`
  overflow: hidden;
  position: relative;
  margin-bottom: ${(p) => p.theme.spacing(4)}px;
  background: ${(p) => p.background};
`;

const Inner = styled('div')`
  max-width: 1000px;
  position: relative;
  margin: 0 auto;
  padding: ${(p) => p.theme.spacing(4)}px 0 ${(p) => p.theme.spacing(8)}px;

  @media (max-width: 800px) {
    padding: ${(p) => p.theme.spacing(4)}px ${(p) => p.theme.spacing(2)}px;
  }
`;

const TitleWrapper = styled('div')`
  max-width: 700px;
  position: relative;
  z-index: 2;

  @media (max-width: 800px) {
    text-align: center;
  }
`;

const HowItWorksWrapper = styled<'div', { mode: 'light' | 'dark' }>('div')`
  margin-top: ${(p) => p.theme.spacing(4)}px;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
  color: ${(p) => p.theme.palette.grey['100']};
  position: relative;
  z-index: 1;

  a {
    border-bottom: 1px solid;
  }

  @media (max-width: 800px) {
    margin-top: ${(p) => p.theme.spacing(4)}px;
    margin-bottom: ${(p) => p.theme.spacing(1)}px;
  }
`;

const Headline = styled('h1')`
  font-weight: 600;
  font-size: 3.75rem;
  line-height: 1.2;
  position: relative;
  z-index: 1;

  @media (max-width: 800px) {
    font-size: 2.5rem;
    line-height: 1.2;
  }

  @media (max-width: 600px) {
    font-size: 2rem;
    line-height: 1.2;
  }
`;

export const RankingsHero: React.FC<{
  headline: React.ReactNode;
  topic: Topic;
  parentUrl: string;
}> = ({ headline, topic, parentUrl, children }) => {
  return (
    <HeroWrapper background={topic.background}>
      <Inner>
        <TrendsNav to={parentUrl} mode={topic.mode} />
        <TitleWrapper>
          <Headline>{headline}</Headline>
          <HowItWorksWrapper mode={topic.mode}>
            <strong>Updated Hourly</strong> (
            <Link to="/trends/how-it-works/">How it Works</Link>)
          </HowItWorksWrapper>
        </TitleWrapper>
      </Inner>
      {children}
      <HeroPattern patternColor={topic.color} pattern={topic.pattern} />
    </HeroWrapper>
  );
};
